import Vue from 'vue';

export default {
  get: async () => Vue.http.get('events/talents-category')
    .then((res) => res.json()),

  getById: async (id) => Vue.http.get(`events/talents-category/${id}`)
    .then((res) => res.json()),

  delete: async (id) => Vue.http.delete(`events/talents-category/${id}`)
    .then((res) => res.json()),

  update: async (model, id) => Vue.http.put(`events/talents-category/${id}`, model)
    .then((res) => res.json()),
};
