<template>
  <div>
    <v-select
      :placeholder="$t('artistsManagement.selectItem')"
      v-if="enabled"
      v-model="selectedItem"
      :options="list.filter((t) => !t.hide)"
      class="mr-3 mb-2"
      @input="onAddItem"
    >
      <span slot="no-options">{{ $t("inputs.emptyDropdown") }}</span>
    </v-select>

    <b-badge
      href="javascript:void(0)"
      variant="primary"
      class="mr-2 mb-2 py-2 px-3"
      pill
      @click="onRemoveItem(item)"
      v-for="item in list.filter((t) => t.hide)"
      :key="item.id"
    >
      {{ item.label }}
      <b-icon icon="trash" />
    </b-badge>
  </div>
</template>

<script>
export default {
  props: ['value', 'items', 'single'],

  data() {
    return {
      selectedItem: undefined,
      data: [],
    };
  },

  methods: {
    onAddItem(selectedItem) {
      const lst = [...this.value];
      lst.push(selectedItem.value);

      this.selectedItem = null;
      this.$emit('input', lst);
    },

    onRemoveItem(selectedItem) {
      this.selectedItem = null;

      this.$emit(
        'input',
        this.value.filter((i) => i !== selectedItem.value),
      );
    },
  },

  computed: {
    max() {
      return this.$attrs.max || 99999;
    },
    enabled() {
      return (
        this.value.filter(
          (v) => this.items.filter((i) => i.id === v).length > 0,
        ).length < this.max
      );
    },
    list() {
      return this.items
        .map((t) => ({
          value: t.id,
          label: this.$i18n.locale === 'pt' ? t.namePT : t.nameEN,
          hide: this.value.indexOf(t.id) >= 0,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
