<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.talents') }}
        </h3>

        <router-link :to="{ name: 'events-talents' }">
          <b-button size="sm" class="ml-3" variant="info">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-backspace-fill mr-2"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241
                7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2
                2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147
                2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0
                1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976
                8 5.829 5.854z"
              />
            </svg>
            {{ $t('buttons.back') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <form @submit.prevent="onSubmit" v-if="loaded">
      <b-row>
        <b-col lg="12" sm="12" class="mb-3">
          <h4 class="mb-2">{{ $t('artistsManagement.info') }}</h4>
        </b-col>

        <b-col lg="7" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.name')" class="mb-3">
            <b-form-input v-model="model.name" maxlength="100" type="text" />
          </b-input-group>

          <b-form-checkbox
            v-model="model.isActive"
            class="mb-3"
            name="check-button"
            switch
          >
            {{ $t('inputs.active') }}
          </b-form-checkbox>

          <TextArea
            :label="$t('artistsManagement.aboutEN')"
            v-model="model.aboutEN"
            maxlength="500"
          />

          <TextArea
            :label="$t('artistsManagement.aboutPT')"
            v-model="model.aboutPT"
            maxlength="500"
          />

          <b-input-group
            :prepend="$t('artistsManagement.country')"
            class="mb-2"
          >
            <v-select
              :placeholder="$t('artistsManagement.country')"
              v-model="model.country"
              :options="countries"
            >
              <span slot="no-options">{{ $t('inputs.emptyDropdown') }}</span>
            </v-select>
          </b-input-group>
          <b-form-group
            :label="$t('artistsManagement.profilePicture')"
            class="mb-2"
          >
            <ImageUploader minHeight="450" minWidth="450" v-model="upload" />
          </b-form-group>
        </b-col>
        <b-col lg="5" sm="12" class="mb-3">
          <img
            :src="upload.preview"
            v-if="upload.preview"
            class="preview mb-1"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" sm="12" class="mb-3">
          <h5>Meet & Greet</h5>

          <b-input-group prepend="Link ticketeira" type="url" class="mb-2">
            <b-input
              v-model="model.meetGreetLink"
              type="url"
              maxlength="100"
              :required="linkRequired"
            />
          </b-input-group>

          <b-form-checkbox
            v-model="model.salve"
            class="mb-3"
            name="check-button"
            switch
          >
            {{ $t('inputs.inbox') }}
          </b-form-checkbox>

          <b-form-checkbox
            v-model="model.oneOnOne"
            class="mb-3"
            name="check-button"
            switch
          >
            {{ $t('inputs.oneonone') }}
          </b-form-checkbox>

          <b-form-checkbox
            v-model="model.meetGreet"
            class="mb-3"
            name="check-button"
            switch
          >
            {{ $t('inputs.meetGreet') }}
          </b-form-checkbox>

          <b-form-checkbox
            v-model="model.autograph"
            class="mb-3"
            name="check-button"
            switch
          >
            {{ $t('inputs.autograph') }}
          </b-form-checkbox>

          <h5>{{ $t('inputs.categories') }}</h5>
          <CategorySelection
            :items="categories"
            v-model="model.categories"
            :max="30"
          />
        </b-col>
      </b-row>

      <hr />
      <b-row>
        <b-col sm="12">
          <h4>{{ $t('general.contact') }}</h4>
          <p class="mb-4">
            {{ $t('artistsManagement.socialMediaDisclaimer') }}
          </p>
        </b-col>
        <b-col lg="6" sm="12" class="mb-4">
          <b-input-group prepend="Email" type="email" class="mb-2">
            <b-input v-model="model.links.email" type="email" maxlength="100" />
          </b-input-group>

          <b-input-group prepend="Twitter" type="text" class="mb-2">
            <b-input v-model="model.links.twitter" type="url" maxlength="400" />
          </b-input-group>

          <b-input-group prepend="Facebook" type="text" class="mb-2">
            <b-input
              v-model="model.links.facebook"
              type="url"
              maxlength="400"
            />
          </b-input-group>

          <b-input-group prepend="Instagram" type="text" class="mb-2">
            <b-input
              v-model="model.links.instagram"
              type="url"
              maxlength="400"
            />
          </b-input-group>

          <b-input-group prepend="Tiktok" type="text" class="mb-2">
            <b-input v-model="model.links.tiktok" type="url" maxlength="400" />
          </b-input-group>

          <b-input-group prepend="Discord" type="text" class="mb-2">
            <b-input v-model="model.links.discord" type="url" maxlength="400" />
          </b-input-group>

          <b-input-group prepend="Twitch" type="text" class="mb-2">
            <b-input v-model="model.links.twitch" type="url" maxlength="400" />
          </b-input-group>

          <b-input-group prepend="Youtube" type="text" class="mb-2">
            <b-input v-model="model.links.youtube" type="url" maxlength="400" />
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" class="text-right">
          <b-button :disabled="loading" type="submit" variant="success">
            {{ $t('buttons.save') }}
          </b-button>
        </b-col>
      </b-row>
    </form>

    <b-row v-else>
      <b-col cols="12">
        <h5 class="text-center mt-4">{{ $t('messages.loading') }}...</h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import CategorySelection from '../../../../components/organisms/CategorySelection.vue';
import Countries from '../../../../services/Countries';
import Talents from '../../../../services/Talents';
import Toast from '../../../../assets/js/toast';
import ImageUploader from '../../../../components/organisms/ImageUploader.vue';
import TextArea from '../../../../components/organisms/TextArea.vue';
import 'vue-datetime/dist/vue-datetime.css';
import EventsTalentsCategories from '../../../../services/EventsTalentsCategories';

export default {
  props: ['type'],

  components: {
    CategorySelection,
    ImageUploader,
    TextArea,
  },

  data() {
    const data = {
      isEdit: false,
      loaded: false,
      loading: true,
      deleteModal: false,
      file: null,
      categories: [],
      embed: undefined,
      dataCountries: [],

      daysOptions: [
        { text: '04/12', value: '2020-12-04' },
        { text: '05/12', value: '2020-12-05' },
        { text: '06/12', value: '2020-12-06' },
      ],

      upload: {
        file: null,
        preview: null,
        blob: null,
      },

      model: {
        name: '',
        email: '',
        aboutPT: '',
        aboutEN: '',
        meetGreetLink: '',
        links: {},
        categories: [],
        oneOnOne: false,
        autograph: false,
        meetGreet: false,
        salve: false,
        isActive: false,
      },
    };

    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  watch: {
    // eslint-disable-next-line func-names
    '$route.path': function () {
      this.onLoad();
    },
  },

  methods: {
    async onLoad() {
      this.loading = true;
      await this.onLoadUser();

      this.loading = false;
      this.loaded = true;
    },

    async onLoadUser() {
      try {
        const { id } = this.$route.params;
        this.categories = await EventsTalentsCategories.get();

        this.dataCountries = await Countries.get();

        if (id) {
          this.isEdit = true;

          const obj = await Talents.getById(id);

          this.model = {
            ...obj,
            links: obj.links || {},
            categories: obj.categories.map((c) => c.id),
            country: obj.country
              ? {
                value: obj.country ? obj.country.id : null,
                label:
                    this.$i18n.locale === 'pt'
                      ? obj.country.namePT
                      : obj.country.nameEN,
              }
              : undefined,
          };
          if (obj.profilePicture) this.upload.preview = `${Vue.prototype.VUE_APP_S3_URL}/talents/${obj.profilePicture}`;
        }
      } catch (e) {
        Sentry.captureException(e);

        Toast.error(this, e);
        console.log('Falha ao carregar!', e);
      }
    },

    async onSubmit() {
      try {
        this.loading = true;
        const action = this.isEdit ? 'update' : 'save';

        const fd = new FormData();
        let objPic;
        if (this.upload.file) {
          fd.append('file', this.upload.blob, this.upload.file.name);
          objPic = await Talents.upload(fd);
        }
        const form = { ...this.model };
        form.profilePicture = objPic ? objPic.filename : form.profilePicture;
        if (form.country) {
          form.country = {
            id: form.country.value,
          };
        }
        const talent = await Talents[action](form, form.id);

        Toast.success(this, 'messages.saveSuccess');

        setTimeout(() => {
          if (!this.isEdit) {
            this.$router.push({
              name: 'events-talents-edit',
              params: { id: talent.id },
            });
          }
          this.loading = false;
        }, 1000);
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
        this.loading = false;
      }
    },
  },
  computed: {
    linkRequired() {
      return !!(
        this.model.salve
        || this.model.oneOnOne
        || this.model.meetGreet
        || this.model.autograph
      );
    },
    countries() {
      return this.dataCountries.map((t) => ({
        value: t.id,
        label: this.$i18n.locale === 'pt' ? t.namePT : t.nameEN,
      }));
    },
  },
};
</script>

<style lang="sass" scoped>
.module
  min-width: 150px
.preview
  max-height: 225px
.v-select
  width: calc(100% - 85px)
iframe
  width: 100%
.vdatetime
  flex-grow: 1
</style>
