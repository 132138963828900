import Vue from 'vue';

export default {
  get: async () => Vue.http.get('country')
    .then((res) => res.json()),

  getLanguages: async () => Vue.http.get('country/languages')
    .then((res) => res.json()),

};
